import React from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'utils/formatDate';
import { formatMoney } from 'utils/formatMoney';
import { IoMdTrash, IoMdCreate  } from 'react-icons/io';

const TablePricesScreen = ({ prices, onSetPriceValues, idsPricesDelete,  inputFilled, onInputFilled, onSetIdsPricesDelete, onSetFormData }) => {
    const { t } = useTranslation();  

    const editPriceList = (index, priceId, price, manufacturingDatetime, expirationDatetime, minimalOrder) => {
        try {
            const regex = /^(\d{4}-\d{2}-\d{2}).*/;

            let manufacturingDatetimeFormatted = manufacturingDatetime.match(regex);
            let expirationDatetimeFormatted = expirationDatetime.match(regex);

            onSetPriceValues(prevState => ({
                ...prevState,
                price: (price / 100).toFixed(2),
                manufacturingDatetime: manufacturingDatetimeFormatted[1],
                expirationDatetime: expirationDatetimeFormatted[1],
                minimalOrder: (minimalOrder / 100).toFixed(2),
            }));

            if (priceId) {
                const newProductDelete = [...idsPricesDelete];

                newProductDelete.push(priceId);

                onSetIdsPricesDelete(newProductDelete);
            }

            const updatedPrices = [...prices];

            updatedPrices.splice(index, 1);

            onSetFormData(prevState => ({
                ...prevState,
                prices: updatedPrices
            }));
        }
        catch {
            showToast('error', t('toast-message.insert-products.screen.add-price.remove.error'));
        }
        finally {
            onInputFilled(true);
        }
    }

    const removePriceList = (index, priceId) => {
        try {
            if (priceId) {
                const newProductDelete = [...idsPricesDelete];

                newProductDelete.push(priceId);

                onSetIdsPricesDelete(newProductDelete);
            }

            const updatedPrices = [...prices];

            updatedPrices.splice(index, 1);

            onSetFormData(prevState => ({
                ...prevState,
                prices: updatedPrices
            }));
        }
        catch {
            showToast('error', t('toast-message.insert-products.screen.add-price.remove.error'));
        }
    }

    return (
        prices.length == 0 ? null 
        : <div className='container-table'>
            <Table className='table' id='pricesTable' responsive>
                <thead>
                    <tr>
                        <th className='width-s'>{t('products-table.price')}</th>
                        <th className='width-md'>{t('products-table.price.manufacturing-date-time')}</th>
                        <th className='width-md'>{t('products-table.price.expiration-date-time')}</th>
                        <th className='width-md'>{t('products-table.price.minimal-order')}</th>
                        <th className='width-xs'>{t('products-table.actions')}</th>
                    </tr>
                </thead>
                <tbody id='tbody'>
                    {
                        prices.map((price, index) => (
                            <tr>
                                <td>{formatMoney(price.price / 100)}</td>
                                <td>{formatDate(price.manufacturingDatetime)}</td>
                                <td>{formatDate(price.expirationDatetime)}</td>
                                <td>{formatMoney(price.minimalOrder / 100)}</td>
                                <td>
                                    <button className='remove-prices-button' type='button' disabled={inputFilled} onClick={() => editPriceList(index, price.id, price.price, price.manufacturingDatetime, price.expirationDatetime, price.minimalOrder)}><IoMdCreate /></button>
                                    <button className='remove-prices-button' type='button' onClick={() => removePriceList(index, price.id)}><IoMdTrash /></button>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
        </div>
    )
}

export default TablePricesScreen