import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import NavHomeComponent from 'components/navHomeComponent.js';
import StatusOrderButton from 'components/statusOrderButtonComponent.js';
import SearchComponent from 'components/searchComponent';
import './ordersScreen.css'
import ButtonSortComponent from 'components/buttonSortComponent';
import { useTranslation } from 'react-i18next';
import orderService from 'services/orderService';
import LoadingComponent from 'components/loadingComponent';
import { ToastComponent, showToast } from 'utils/showToast';
import ButtonFilterComponent from 'components/buttonFilterComponent';
import { TfiReload } from 'react-icons/tfi';
import downloadExcel from 'utils/downloadExcel.js';
import { FaFileExcel } from "react-icons/fa";

let page = 1;

const OrdersScreen = () => {
    const { t } = useTranslation();

    const [orderAccountId, setOrderAccountId] = useState('');
    const [orders, setOrders] = useState([]);
    const [pageOrders, setPageOrders] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [haveOrdersYet, setHaveOrdersYet] = useState(false);
    const [isFilter, setIsFilter] = useState(false);

    const getAllOrders = async () => {
        setIsLoading(true);

        try {
            const response = await orderService.get(pageOrders);
            
            response.map((order) => {
                if (order.status == 'FINISHED') {
                    order.status = t('orders-table.status.approved');
                    order.statusStyle = 'approved';
                } else if (order.status == 'CANCELLED') {
                    order.status = t('orders-table.status.refused');
                    order.statusStyle = 'refused';
                } else {
                    order.status = t('orders-table.status.pending');
                    order.statusStyle = 'pending';
                }
            });

            if (page != 1) {
                setOrders((orders) => [...orders, ...response]);
            } else {
                setOrders(response);
            }

            await checkHaveInNextPage(pageOrders);
        } 
        catch {
            showToast('error', t('toast-message.orders.get.error'));
        } 
        finally {
            setOrderAccountId('');
            setIsLoading(false);
        }
    };

    const getAllOrdersByAccountId = async (accountId) => {
        setIsLoading(true);

        try {
            const response = await orderService.getByAccountId(accountId);

            response.map((order) => {
                if (order.status == 'FINISHED') {
                    order.status = t('orders-table.status.approved');
                    order.statusStyle = 'approved';
                } else if (order.status == 'CANCELLED') {
                    order.status = t('orders-table.status.refused');
                    order.statusStyle = 'refused';
                } else {
                    order.status = t('orders-table.status.pending');
                    order.statusStyle = 'pending';
                }
            });

            setHaveOrdersYet(false);
            setOrders(response);
        }
        catch {
            showToast('error', t('toast-message.orders.get.error'));
        }
        finally {
            setOrderAccountId(accountId);
            setIsLoading(false);
        }
    }

    const checkHaveInNextPage = async (pageOrder) => {
        const pageNumber = pageOrder + 1;

        const response = await orderService.get(pageNumber);

        if (response.length > 0) {
            await setHaveOrdersYet(true);
        } else {
            await setHaveOrdersYet(false);
        }
    };

    useEffect(() => {
        getAllOrders();
    }, [pageOrders]);

    const generateExcel = () => {
        let arrayOrdersFormatted = [];
    
        orders.forEach(order => {
            arrayOrdersFormatted.push([
                order.name,
                order.cnpj,
                order.date,
                order.status,
                order.totalValue,
            ]);
        });
    
        try {
            downloadExcel(t('nav-home.orders'), ['name', 'cnpj', 'date', 'status', 'totalValue'], arrayOrdersFormatted);
        }
        catch {
            showToast('error', t('toast-message.orders.download.error'));
        }
    };

    const generateExcelUnitOrder = (order) => {
        let arrayOrderFormatted = [];
    
        arrayOrderFormatted.push([
            order.name,
            order.cnpj,
            order.date,
            order.status,
            order.totalValue,
        ]);

        try {
            downloadExcel(t('nav-home.orders'), ['name', 'cnpj', 'date', 'status', 'totalValue'], arrayOrderFormatted);
        }
        catch {
            showToast('error', t('toast-message.orders.download.error'));
        }
    }

    const refresh = () => window.location.reload();

    return (
        <NavHomeComponent screen={
        <>
            <ToastComponent />
            <SearchComponent screen='orders' arrayToFilter={orders} onArrayFiltered={setOrders}/>
            <div className='menu-order'>
                <ul>
                    <li><ButtonFilterComponent type={'status'} accountId={orderAccountId != '' ? orderAccountId : ''} title={t('orders-table.status.approved')} filter={'finished'} isFilter={isFilter} onIsFilter={setIsFilter} arrayToFilter={orders} onSetArrayFiltered={setOrders} onSetLoading={setIsLoading} refresh={ orderAccountId != '' ? () => getAllOrdersByAccountId(orderAccountId) : () => refresh()} styles={'status-filter bgcolor-approved'} /></li>
                    <li><ButtonFilterComponent type={'status'} accountId={orderAccountId != '' ? orderAccountId : ''} title={t('orders-table.status.pending')} filter={'pending'} isFilter={isFilter} onIsFilter={setIsFilter} arrayToFilter={orders} onSetArrayFiltered={setOrders} onSetLoading={setIsLoading} refresh={ orderAccountId != '' ? () => getAllOrdersByAccountId(orderAccountId) : () => refresh()} styles={'status-filter bgcolor-pending'} /></li>
                    <li><ButtonFilterComponent type={'status'} accountId={orderAccountId != '' ? orderAccountId : ''} title={t('orders-table.status.refused')} filter={'cancelled'} isFilter={isFilter} onIsFilter={setIsFilter} arrayToFilter={orders} onSetArrayFiltered={setOrders} onSetLoading={setIsLoading} refresh={ orderAccountId != '' ? () => getAllOrdersByAccountId(orderAccountId) : () => refresh()} styles={'status-filter bgcolor-refused'} /></li>
                </ul>
                <div className='d-flex'>
                    <button disabled={isLoading} className='button-export' onClick={() => generateExcel()}>{t('buttons.export-order')}</button>
                    <button disabled={isLoading} className='button-filter' onClick={() => refresh()}><TfiReload /></button>
                </div>
            </div>
            <div className='container-table mt-4'>
            { 
                isLoading
                ? <LoadingComponent />
                : orders.length == 0
                    ? <h1 className='title-warning'>{t('orders-screen.orders.empty')}</h1>
                    : <Table className='table' id='ordersTable' responsive>
                        <thead>
                            <tr>
                                <th className='width-md'>{t('orders-table.client')} <ButtonSortComponent keyFilter='name' typeFilter='string' arrayToFilter={orders} onSetArrayToFilter={setOrders}/></th>
                                <th className='width-md'>{t('orders-table.cnpj')} </th>
                                <th className='width-md'>{t('orders-table.date')} <ButtonSortComponent keyFilter='date' typeFilter='date' arrayToFilter={orders} onSetArrayToFilter={setOrders}/></th>
                                <th className='width-s'>{t('orders-table.value')} <ButtonSortComponent keyFilter='totalValue' typeFilter='value' arrayToFilter={orders} onSetArrayToFilter={setOrders}/></th>
                                <th className='width-s'>{t('orders-table.status')} <ButtonSortComponent keyFilter='status' typeFilter='string' arrayToFilter={orders} onSetArrayToFilter={setOrders}/></th>
                                <th className='width-s'>{t('orders-table.actions')}</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            orders.map((order) => (
                                <tr key={order.id}>
                                    <td><a onClick={() => getAllOrdersByAccountId(order.accountId)}>{order.name}</a></td>
                                    <td>{order.cnpj}</td>
                                    <td>{order.date}</td>
                                    <td>{order.totalValue}</td>
                                    <td>
                                        <card  
                                            className={'status' + ` bgcolor-${order.statusStyle ? order.statusStyle : ''}`}
                                        >
                                            {order.status ? order.status : t('orders-table.status.undefined').toUpperCase()}
                                        </card>
                                    </td>
                                    <td className='actions-order'>
                                        {
                                            order.statusStyle == 'pending' && !isLoading 
                                            ? <a>
                                                <StatusOrderButton orderId={order.id}/>
                                                </a>
                                            : null
                                        }
                                        <button className='button-filter mt-1' onClick={() => generateExcelUnitOrder(order)}><FaFileExcel /></button>
                                    </td> 
                                </tr>
                            ))
                        }  
                        </tbody>
                    </Table>
            }
            </div>
            {
                haveOrdersYet
                ? <div className='div-see-more'>
                    <button disabled={isLoading} className='button-more' onClick={() => setPageOrders(page++)}>{t('buttons.see-more')}</button>
                </div>
                : null
            }
        </>
        }/>
    )
}

export default OrdersScreen
