import React from 'react';
import { useTranslation } from 'react-i18next';
import './toogleLanguageButton.css'

function ToggleLanguageButton() {
  const { i18n, t } = useTranslation();

  const toggleLanguage = () => {
    const newLanguage = i18n.language === 'en' ? 'pt' : 'en';
    i18n.changeLanguage(newLanguage);
  };

  return (
    <button className='toogle-language-button' type='button' onClick={toggleLanguage}>
      {t('buttons.toogle-language')} 
      {i18n.language === 'en' ? 
            t('settings-screen.language.portuguese') : 
            t('settings-screen.language.english')
      }
    </button>
  );
}

export default ToggleLanguageButton;
